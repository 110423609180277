import { FC, lazy as reactLazy, Suspense } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import { SignOut } from 'components/SignOut'

import {
  orderPath,
  dashboardPath,
  destinationRoutingsPath,
  destinationsPath,
  dispatchSearchPath,
  myAccountPath,
  orderDetailsPath,
  quoteDetailsPath,
  reportQueuePath,
  signOutPath,
  viewsPath,
  userAdminPath,
  reportSchedulePath,
  reportSubmissionPath,
  reportsPath,
  reportGridPath,
  uploadsPath,
  programsPath,
  companyProfile,
  programPath,
} from './Routes'

import { ErrorBoundary } from './ErrorBoundary'
import { ExpiredPasswordForm } from '../ExpiredPasswordForm'
import { ForgotPasswordPage } from '../ForgotPasswordPage'
import { ForgotPasswordSuccessPage } from '../ForgotPasswordSuccessPage'
import { LogInPage } from '../LogInPage'
import { LogInModal } from '../LogInModal'
import { Page } from 'components/Page'
import { ProtectedRoute } from '../ProtectedRoute'
import { ResetPasswordPage } from '../ResetPasswordPage'
import { UNAUTHORIZED_ROUTES } from '../../Routes'
import { UnauthorizedPage } from '../UnauthorizedPage'
import { UnAuthorizedResetPWSuccess } from '../UnauthorizedResetPWSuccess'
import { useTheme } from 'contexts/ThemeContext'
import { Redirect } from 'react-router'

const lazy: typeof reactLazy = fn => {
  return reactLazy(() =>
    fn().catch(error => {
      if (error.name === 'ChunkLoadError') window.location.reload()
      throw error
    }),
  )
}

const CreateAccountPage = lazy(() => import('pages/CreateAccountPage'))
const DashboardPage = lazy(() => import('pages/DashboardPage'))
const DestinationRoutingsPage = lazy(() => import('pages/DestinationRoutingsPage'))
const DestinationsPage = lazy(() => import('pages/DestinationsPage'))
const DispatchesPage = lazy(() => import('pages/DispatchesPage'))
const DispatchSearchResultsPage = lazy(() => import('pages/DispatchSearchResultsPage'))
const ImpersonatePage = lazy(() => import('pages/ImpersonatePage'))
const MyAccountPage = lazy(() => import('pages/MyAccountPage'))
const OrderDetailsPage = lazy(() => import('pages/OrderDetailsPage'))
const OrderPages = lazy(() => import('pages/OrderPages'))
const QuoteDetailsPage = lazy(() => import('pages/QuoteDetailsPage'))
const RedirectPage = lazy(() => import('pages/RedirectPage'))
const ReportQueuePage = lazy(() => import('pages/ReportQueuePage'))
const UserAdminPage = lazy(() => import('pages/UserAdminPage'))
const ReportSchedulePage = lazy(() => import('pages/ReportSchedulePage'))
const ReportSubmissionPage = lazy(() => import('pages/ReportSubmissionPage'))
const ReportsPage = lazy(() => import('pages/ReportsPage'))
const ReportGridPage = lazy(() => import('pages/ReportGridPage'))
const UploadsPage = lazy(() => import('pages/UploadsPage'))
const ProgramsPage = lazy(() => import('pages/ProgramsPage'))
const ProgramPage = lazy(() => import('pages/ProgramPage'))
const CompanyProfilePage = lazy(() => import('pages/CompanyProfile'))

const LoadingPage: FC = () => <Page />

export const Router = () => {
  const { brandPath } = useTheme()

  return (
    <BrowserRouter basename={brandPath} key={brandPath}>
      <ErrorBoundary>
        <Suspense fallback={<LoadingPage />}>
          <LogInModal />
          <Switch>
            <Route exact path={UNAUTHORIZED_ROUTES.createAccount}>
              <CreateAccountPage />
            </Route>

            <Route exact path={UNAUTHORIZED_ROUTES.alternativeLogin}>
              <Redirect to={UNAUTHORIZED_ROUTES.login} />
            </Route>

            {/* Branded login page */}
            <Route exact path={`${UNAUTHORIZED_ROUTES.login}`}>
              <LogInPage />
            </Route>

            {/* Default login page */}
            <Route exact path={UNAUTHORIZED_ROUTES.login}>
              <LogInPage />
            </Route>

            <Route exact path={UNAUTHORIZED_ROUTES.passwordExpired}>
              <UnauthorizedPage>
                <ExpiredPasswordForm />
              </UnauthorizedPage>
            </Route>

            <Route exact path={UNAUTHORIZED_ROUTES.forgotPassword}>
              <UnauthorizedPage maxWidth={525}>
                <ForgotPasswordPage />
              </UnauthorizedPage>
            </Route>

            <Route exact path={UNAUTHORIZED_ROUTES.forgotPasswordSuccess}>
              <UnauthorizedPage>
                <ForgotPasswordSuccessPage />
              </UnauthorizedPage>
            </Route>

            <Route path={UNAUTHORIZED_ROUTES.impersonate()}>
              <UnauthorizedPage>
                <ImpersonatePage />
              </UnauthorizedPage>
            </Route>

            <Route exact path={`${UNAUTHORIZED_ROUTES.resetPassword}/:uid`}>
              <UnauthorizedPage>
                <ResetPasswordPage />
              </UnauthorizedPage>
            </Route>

            <Route exact path={UNAUTHORIZED_ROUTES.resetPasswordSuccess}>
              <UnauthorizedPage>
                <UnAuthorizedResetPWSuccess />
              </UnauthorizedPage>
            </Route>

            <Route path={UNAUTHORIZED_ROUTES.redirect()}>
              <RedirectPage />
            </Route>

            <ProtectedRoute path={dashboardPath} exact>
              <DashboardPage />
            </ProtectedRoute>
            <ProtectedRoute path={destinationRoutingsPath} exact>
              <DestinationRoutingsPage />
            </ProtectedRoute>
            <ProtectedRoute path={destinationsPath} exact>
              <DestinationsPage />
            </ProtectedRoute>
            <ProtectedRoute path={dispatchSearchPath} exact>
              <DispatchSearchResultsPage />
            </ProtectedRoute>
            <ProtectedRoute path={myAccountPath} exact>
              <MyAccountPage />
            </ProtectedRoute>
            <ProtectedRoute path={orderDetailsPath()} exact>
              <OrderDetailsPage />
            </ProtectedRoute>
            <ProtectedRoute path={orderPath}>
              <OrderPages />
            </ProtectedRoute>
            <ProtectedRoute path={quoteDetailsPath()} exact>
              <QuoteDetailsPage />
            </ProtectedRoute>
            <ProtectedRoute path={reportQueuePath} exact>
              <ReportQueuePage />
            </ProtectedRoute>
            <ProtectedRoute path={userAdminPath} exact>
              <UserAdminPage />
            </ProtectedRoute>
            <ProtectedRoute path={viewsPath}>
              <DispatchesPage />
            </ProtectedRoute>
            <ProtectedRoute path={reportSchedulePath} exact>
              <ReportSchedulePage />
            </ProtectedRoute>
            <Route path={reportSubmissionPath} exact>
              <ReportSubmissionPage />
            </Route>
            <ProtectedRoute path={reportsPath} exact>
              <ReportsPage />
            </ProtectedRoute>
            <ProtectedRoute path={reportGridPath} exact>
              <ReportGridPage />
            </ProtectedRoute>
            <ProtectedRoute path={uploadsPath} exact>
              <UploadsPage />
            </ProtectedRoute>
            <ProtectedRoute path={programsPath} exact>
              <ProgramsPage />
            </ProtectedRoute>
            <ProtectedRoute path={programPath} exact>
              <ProgramPage />
            </ProtectedRoute>

            <ProtectedRoute path={signOutPath} exact>
              <SignOut />
            </ProtectedRoute>

            <ProtectedRoute path={companyProfile} exact>
              <CompanyProfilePage />
            </ProtectedRoute>

            <Redirect exact from="/" to={viewsPath} />
            <Route>
              <Page>
                <h1>Not Found</h1>
              </Page>
            </Route>
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </BrowserRouter>
  )
}
