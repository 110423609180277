export const ENDPOINTS = {
  account: '/account',
  address: '/address',
  alert: '/alert',
  assets: '/asset',
  comments: '/comment',
  company: '/company',
  dashboard: '/dashboard',
  destination: '/destination',
  error: '/error',
  file: '/file_upload',
  grid: '/grid',
  gridConfigs: '/grid_config',
  job: '/job',
  mixpanel: '/mixpanel',
  program: '/program',
  quote: '/quote',
  reportQueue: '/report_queue',
  reportSchedule: '/report_schedule',
  setting: '/setting',
  user: '/user',
}
