import * as React from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { Formik } from 'formik'
import { Link, useHistory } from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'
import * as yup from 'yup'

import * as routes from 'components/Router/Routes'
import styles from './LogInForm.module.scss'
import { TextField } from 'components/TextField'
import { TFormSubmit } from 'types'
import { useTheme } from 'contexts/ThemeContext'
import { PasswordField } from 'components/PasswordField/PasswordField'

const validationSchema = yup.object().shape({
  password: yup.string().required(),
  username: yup.string().required(),
})

export type FormValues = {
  password: string
  username: string
}

export type Props = {
  onSubmit: TFormSubmit<FormValues, void>
  showCreateNewAccount?: boolean
  showForgotPassword?: boolean
  showHeader?: boolean
  showStartNewReturn?: boolean
}

export const LogInForm = ({
  onSubmit,
  showForgotPassword,
  showHeader = true,
  showStartNewReturn,
}: Props): JSX.Element => {
  const { promoCode } = useTheme()
  const history = useHistory()
  const usernameRef = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    usernameRef.current?.focus()
  }, [])

  React.useEffect(() => {
    // Should redirect to create account when onboarding with promo code.
    // Should happen only once
    if (promoCode && !sessionStorage.getItem('wasRedirectedToCreateAccount')) {
      // Workaround over redirection race condition
      setTimeout(() => {
        sessionStorage.setItem('wasRedirectedToCreateAccount', 'true')
        history.push(routes.createAccount)
      }, 10)
    }
  }, [promoCode, history])

  return (
    <div>
      <Formik
        initialValues={{ password: '', username: '' }}
        onSubmit={async (values, formikBag) => {
          try {
            await onSubmit(values)
          } catch (error) {
            formikBag.setStatus((error as Error).message)
          }
        }}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isSubmitting, status }) => {
          if (isSubmitting) {
            usernameRef.current?.focus()
          }

          return (
            <Form className={styles.Form} onSubmit={handleSubmit}>
              {showHeader && <h2 className="text-center mb-5">Sign In</h2>}

              <TextField
                className="mb-3"
                hideValidation
                label="Username"
                name="username"
                ref={usernameRef}
              />
              <PasswordField className="mb-3" label="Password" name="password" />

              {showForgotPassword && (
                <Row className={styles.RightAlignedRow}>
                  <Link className={styles.ForgotPassword} to={routes.emailPasswordResetPath}>
                    Forgot password?
                  </Link>
                </Row>
              )}

              {status && <div className="mb-3 text-center text-error">{status}</div>}

              <Row className={styles.RightAlignedRow}>
                <Button className="w-75 mx-auto" disabled={isSubmitting} type="submit">
                  {isSubmitting ? (
                    <Spinner animation="border" style={{ height: '1rem', width: '1rem' }} />
                  ) : (
                    'Sign In'
                  )}
                </Button>
              </Row>

              {showStartNewReturn && (
                <Row className={styles.RightAlignedRow}>
                  <Link className="w-75 mx-auto" to={routes.createAccount}>
                    <Button className="w-100" variant="dark">
                      Create Account
                    </Button>
                  </Link>
                </Row>
              )}
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
